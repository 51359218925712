
import { ColorPickerColorModel, ColorPickerTheme } from "@/types/localtypes/colorPicker";
import colorPicker from "@caohenghu/vue-colorpicker";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ColorPickerPopover",
  components: { colorPicker },
})
export default class ColorPickerPopover extends Vue {
  @Prop({ default: "dark" })
  private theme!: ColorPickerTheme;
  @Prop({ default: "#ffffff" })
  private initialColor!: string;
  @Prop({ default: 15 })
  private triggerWidth!: number;
  @Prop({ default: 15 })
  private triggerHeight!: number;
  @Prop({ default: 3 })
  private triggerRadius!: number;

  selectedColor = this.initialColor;

  get triggerStyles() {
    return {
      backgroundColor: this.selectedColor,
      width: `${this.triggerWidth}px`,
      height: `${this.triggerHeight}px`,
      borderRadius: `${this.triggerRadius}px`,
    };
  }

  changeColor(color: ColorPickerColorModel) {
    this.selectedColor = color.hex;
    this.$emit("colorChanged", color);
  }
}
