
import ColorPickerPopover from "@/components/common/ColorPickerPopover.vue";
import { ColorPickerColorModel } from "@/types/localtypes/colorPicker";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "MyJFrogIcons",
  components: { ColorPickerPopover },
})
export default class MyJFrogIcons extends Vue {
  iconsMap = [
    "icon-mjf-artifactory",
    "icon-mjf-jcr",
    "icon-mjf-pipelines",
    "icon-mjf-bintray",
    "icon-mjf-edge",
    "icon-mjf-jfmc",
    "icon-mjf-xray",
    "icon-mjf-manage-billing",
    "icon-mjf-manage-subscriptions",
    "icon-mjf-active",
    "icon-mjf-alert",
    "icon-mjf-back",
    "icon-mjf-buy",
    "icon-mjf-canceled",
    "icon-mjf-date",
    "icon-mjf-notification",
    "icon-mjf-provider",
    "icon-mjf-region",
    "icon-mjf-trial",
    "icon-mjf-type",
    "icon-mjf-upgrade",
    "icon-mjf-jfrog",
    "icon-mjf-froggy",
    "icon-mjf-feedback",
    "icon-mjf-pipelines-more",
    "icon-mjf-curved-check",
    "icon-mjf-infinity",
    "icon-mjf-help-filled",
    "icon-mjf-help",
    "icon-mjf-info-filled",
    "icon-mjf-clock",
  ];

  currentColor = "#696969";
  onColorChanged(color: ColorPickerColorModel) {
    const { r, g, b, a } = color.rgba;
    this.currentColor = `rgba(${r}, ${g}, ${b}, ${a})`;
  }
}
