
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VueSlider from "vue-slider-component";
import { Value } from "vue-slider-component/typings/typings";
import { PricingSliderProps } from "@/types/pricingSlider";

@Component({
  name: "PricingSlider",
  components: { VueSlider },
})
export default class PricingSlider extends Vue {
  @Prop({ default: "storage" })
  private dataType!: PricingSliderProps["dataType"];
  @Prop({ default: "aws_and_gcp" })
  private cloudProvider!: PricingSliderProps["cloudProvider"];
  @Prop({ default: true })
  private includePro!: PricingSliderProps["includePro"];
  @Prop({ default: true })
  private includeProX!: PricingSliderProps["includeProX"];
  @Prop({ default: false })
  private onlyPro!: PricingSliderProps["onlyPro"];
  @Prop({ default: null })
  private initialValue!: PricingSliderProps["initialValue"];
  @Prop({ default: 900 })
  private sliderWidth!: PricingSliderProps["sliderWidth"];
  value: number = 0;
  sliderOpts = {
    width: this.sliderWidth,
    height: 6,
    // duration: 0,
    absorb: false,
    clickable: true,
    marks: true,
    lazy: true,
    data: this.sliderData,
    tooltip: "always",
    railStyle: {
      borderRadius: "0px",
    },
  };

  onChange() {
    // The lazy property make the returned values not accurate -> we cannot use the value returned by the event.
    // So we return the real value read directly in the component in the next tick to get always the last value
    // Note that if we remove the lazy proerty the value will be always accurante but a lot of events will be trigged (each drag)
    setTimeout(() => {
      // @ts-ignore
      this.$emit("change", this.$refs.slider.getValue());
    }, 0);
  }
  displayCloudProMetaText(value: number) {
    // display if we are drawing one of :
    // - the cloudProX limit mark (GCP and AWS)
    // - the last mark (in azureMode)
    return (value === this.proXStartValue && this.includePro) || (this.isOnlyPro && value === this.lastValue);
  }
  displayCloudProXMetaText(value: number) {
    return value === this.proXStartValue && this.includeProX;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get cloudProMetaText() {
    return this.isMobile ? "Pro" : "Cloud Pro";
  }

  get cloudProXMetaText() {
    return this.isMobile ? "Pro X - " : "Cloud Pro X - ";
  }
  get cloudProXMetaTextExtra() {
    return this.isMobile ? "Including Xray" : "Including Xray Cloud & 24/7 Support";
  }
  get isOnlyPro() {
    return this.cloudProvider === "azure" || this.onlyPro;
  }

  get proXStartValue() {
    return this.dataType === "storage" ? 125 : 200;
  }

  get dotStyle() {
    return {
      width: "40px",
      height: "40px",
      position: "relative",
      top: "-13px",
      left: "-13px",
      boxShadow: "grey 0px 2px 11px",
    };
  }

  get sliderData() {
    let data: number[] = [];
    if (this.includePro || this.isOnlyPro) {
      data = data.concat(this.cloudProData);
    }
    if (this.includeProX || this.isOnlyPro) {
      data = data.concat(this.cloudProXData);
    }
    return data;
  }
  get sliderDataLength() {
    return this.sliderData.length;
  }
  get sliderDataLastIndex() {
    return this.sliderDataLength - 1;
  }
  get firstValue() {
    return this.sliderData[0];
  }
  get lastValue() {
    return this.sliderData[this.sliderDataLastIndex];
  }

  get cloudProData() {
    return this.dataType === "storage" ? this.cloudProStorageData : this.cloudProDataTransferData;
  }

  get cloudProStorageData() {
    let data = [2];
    for (let index = 5; index < 125; index += 5) {
      data.push(index);
    }
    return data;
  }
  get cloudProDataTransferData() {
    let data = [];
    for (let index = 10; index < 200; index += 5) {
      data.push(index);
    }
    return data;
  }

  get cloudProXData() {
    return this.dataType === "storage" ? this.cloudProXStorageData : this.cloudProXDataTransferData;
  }

  get cloudProXStorageData() {
    let data = [];
    for (let index = 125; index <= 3000; index += 25) {
      data.push(index);
    }
    return data;
  }

  get cloudProXDataTransferData() {
    let data = [];
    for (let index = 200; index <= 8000; index += 40) {
      data.push(index);
    }
    return data;
  }

  isValueGB(value: number) {
    return value < 1000;
  }
  getValueUnit(value: number) {
    const isGB = this.isValueGB(value);
    return isGB ? "GB" : "TB";
  }
  getDisplayedValue(value: number, nbDecimals: number) {
    const isGB = this.isValueGB(value);
    return isGB ? value : (value / 1000).toFixed(nbDecimals);
  }
  tooltipHTML(value: number) {
    const convertedValue = this.getDisplayedValue(value, 2);
    const unit = this.getValueUnit(value);

    let html = `<span class="tooltip-value">${convertedValue}<span class="tooltip-unit">${unit}</span></span>`;
    return html;
  }

  labelHTML(value: number) {
    const labelPart = `<span class="label-value">${this.getDisplayedValue(value, 0)}</span>`;
    const unitPart = `<span class="label-unit">${this.getValueUnit(value)}</span>`;
    let html = `<div class="label-wrapper" text-center>${labelPart}${unitPart}</div>`;
    if (value === this.proXStartValue) {
      html += `<div class="label-meta" primary>Cloud Pro X</div>`;
    }
    return html;
  }

  displayLabel(value: number) {
    // 1st or last or proX limit
    // for azure displaying just first and last
    return (
      value === this.sliderData[0] ||
      value === this.sliderData[this.sliderData.length - 1] ||
      (!this.isOnlyPro && value === this.proXStartValue)
    );
  }

  metaTextStyles(type: "pro" | "proX", markerPosPercent: number) {
    const isForPro = type === "pro";
    const symbol = isForPro ? "-" : "+";
    const markerPositionPixel = (markerPosPercent * this.sliderWidth) / 100;
    let shift = 0;
    if (isForPro) {
      shift = markerPositionPixel / 2;
    } else {
      shift = (this.sliderWidth - markerPositionPixel) / 2;
    }
    return {
      left: symbol + shift + "px",
    };
  }

  mounted() {
    if (this.initialValue) {
      this.value = this.initialValue;
    } else {
      this.value = this.proXStartValue;
    }
  }
}
