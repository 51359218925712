
import ColorPickerPopover from "@/components/common/ColorPickerPopover.vue";
import { ColorPickerColorModel } from "@/types/localtypes/colorPicker";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ArtifactoryIcons",
  components: { ColorPickerPopover },
})
export default class ArtifactoryIcons extends Vue {
  iconsMap = [
    "icon-art-remote-repo",
    "icon-art-archive-2",
    "icon-art-more",
    "icon-art-gradle",
    "icon-art-docker-tags",
    "icon-art-bower",
    "icon-art-import",
    "icon-art-filter",
    "icon-art-set-me-up",
    "icon-art-lock",
    "icon-art-notif-success",
    "icon-art-notif-error",
    "icon-art-docker-command",
    "icon-art-copy-to-clipboard",
    "icon-art-megaphone-on",
    "icon-art-show-in-tree",
    "icon-art-show-in-ci-server",
    "icon-art-show-build-json",
    "icon-art-search-specific",
    "icon-art-menu-search",
    "icon-art-run",
    "icon-art-zap",
    "icon-art-refresh",
    "icon-art-view",
    "icon-art-arrow2",
    "icon-art-vagrant",
    "icon-art-docker-created",
    "icon-art-yum",
    "icon-art-sbt",
    "icon-art-blackduck",
    "icon-art-arrow",
    "icon-art-c-2",
    "icon-art-artifactory-edit",
    "icon-art-unwatch",
    "icon-art-jar",
    "icon-art-untitled50",
    "icon-art-download",
    "icon-art-rpm",
    "icon-art-gems",
    "icon-art-scala",
    "icon-art-general",
    "icon-art-delete-recursive",
    "icon-art-delete-content",
    "icon-art-c-1",
    "icon-art-delete-versions",
    "icon-art-c",
    "icon-art-watch",
    "icon-art-local-repo",
    "icon-art-megaphone",
    "icon-art-move",
    "icon-art-simple-browser",
    "icon-art-datagrid-v",
    "icon-art-re-index",
    "icon-art-virtual-repo",
    "icon-art-v",
    "icon-art-unview",
    "icon-art-pypi",
    "icon-art-drag",
    "icon-art-git-lfs",
    "icon-art-p2",
    "icon-art-npm",
    "icon-art-notif-icon",
    "icon-art-bintray",
    "icon-art-archive",
    "icon-art-cached-repo",
    "icon-art-css",
    "icon-art-debian",
    "icon-art-docker-id",
    "icon-art-copy",
    "icon-art-groovy",
    "icon-art-html",
    "icon-art-java",
    "icon-art-json",
    "icon-art-group",
    "icon-art-nuget",
    "icon-art-pdf",
    "icon-art-deploy",
    "icon-art-go-to-build",
    "icon-art-txt",
    "icon-art-folder",
    "icon-art-xml",
    "icon-art-zip",
    "icon-art-artifacts-new",
    "icon-art-admin-new",
    "icon-art-notif-warning",
    "icon-art-generic",
    "icon-art-export",
    "icon-art-duplicate",
    "icon-art-new",
    "icon-art-ivy",
    "icon-art-info",
    "icon-art-clear",
    "icon-art-pro",
    "icon-art-upload-small",
    "icon-art-close",
    "icon-art-vcs",
    "icon-art-maven",
    "icon-art-docker-size",
    "icon-art-docker-digest",
    "icon-art-home-new",
    "icon-art-builds-new",
    "icon-art-trashcan",
    "icon-art-trash",
    "icon-art-unlock",
    "icon-art-trashcan-restore",
    "icon-art-xray-alert",
    "icon-art-go",
    "icon-art-cocoapods",
    "icon-art-revoke-api",
    "icon-art-unexpire",
    "icon-art-expire",
    "icon-art-pin",
    "icon-art-unpin",
    "icon-art-tar",
    "icon-art-gz",
    "icon-art-search",
    "icon-art-plus-sign",
    "icon-art-distribution-repo",
    "icon-art-xray",
    "icon-art-admin",
    "icon-art-artifacts",
    "icon-art-builds",
    "icon-art-home",
    "icon-art-xray-scanning",
    "icon-art-xray-impact-analysis",
    "icon-art-opkg",
    "icon-art-menu-arrow",
    "icon-art-upload",
    "icon-art-notification-success",
    "icon-art-notification-warning",
    "icon-art-notification-error",
    "icon-art-conan",
    "icon-art-xray-menu-admin",
    "icon-art-xray-menu-alerts",
    "icon-art-xray-ignore-alert",
    "icon-art-composer",
    "icon-art-xray-menu-components",
    "icon-art-uniE924",
    "icon-art-uniE925",
    "icon-art-xray-menu-home",
    "icon-art-xray-menu-reports",
    "icon-art-uniE928",
    "icon-art-uniE929",
    "icon-art-uniE92A",
    "icon-art-xray-menu-watches",
    "icon-art-uniE92C",
    "icon-art-addons-arrow-right",
    "icon-art-addons-arrow-left",
    "icon-art-twitter",
    "icon-art-screw",
    "icon-art-xray-moitoring-alert-new",
    "icon-art-compress",
    "icon-art-external-link",
    "icon-art-up-arrow",
    "icon-art-down-arrow",
    "icon-art-icon-envelop",
    "icon-art-icon-xray-webhook-triangle",
    "icon-art-post_envelop",
    "icon-art-icon-xray-webhook",
    "icon-art-tree_filter-icon",
    "icon-art-logout",
    "icon-art-chef",
    "icon-art-custom-integration",
    "icon-art-quicksetup",
    "icon-art-success",
    "icon-art-notification-info",
    "icon-art-puppet",
    "icon-art-maturity-experimental",
    "icon-art-maturity-development",
    "icon-art-maturity-stable",
    "icon-art-maturity-official",
    "icon-art-edit-pen",
    "icon-art-eula-doc",
    "icon-art-checkmark",
    "icon-art-private-repository",
    "icon-art-navigation-repositories",
    "icon-art-navigation-bundle",
    "icon-art-navigation-products",
    "icon-art-navigation-settings",
    "icon-art-star",
    "icon-art-star-full",
    "icon-art-sort-and-filter",
    "icon-art-get-badge",
    "icon-art-github",
    "icon-art-google",
    "icon-art-linked",
    "icon-art-unlinked",
    "icon-art-remove",
    "icon-art-twitter-dark",
    "icon-art-add",
    "icon-art-docker",
    "icon-art-saml",
    "icon-art-xray-admin",
    "icon-art-xray-artifactory",
    "icon-art-ldap",
    "icon-art-xray-new",
    "icon-art-helm",
    "icon-art-report",
    "icon-art-merge",
    "icon-art-crowd",
    "icon-art-public",
    "icon-art-organization",
    "icon-art-jenkins",
    "icon-art-git",
    "icon-art-golang",
    "icon-art-eplus",
    "icon-art-edge",
    "icon-art-inbox-clear",
    "icon-art-inbox-approve",
    "icon-art-inbox-reply",
    "icon-art-inbox-trash",
    "icon-art-cran",
    "icon-art-default-avatar-package",
    "icon-art-stop",
    "icon-art-xray-policies",
    "icon-art-unread",
    "icon-art-read",
    "icon-art-small-arrow-down",
    "icon-art-copy-to-clipboard-2",
    "icon-art-report-abuse",
    "icon-art-claim-ownership",
    "icon-art-conda",
    "icon-art-thin-arrow",
    "icon-art-failure",
    "icon-art-severity-urgent",
    "icon-art-serverity",
    "icon-art-info-new",
    "icon-art-alert-new",
    "icon-art-success-new",
    "icon-art-alpine",
    "icon-art-data-dashboard",
    "icon-art-sonarqube",
    "icon-art-filter-fill",
    "icon-art-violation",
    "icon-art-pin-on-map",
    "icon-art-key",
  ];

  currentColor = "#696969";
  onColorChanged(color: ColorPickerColorModel) {
    const { r, g, b, a } = color.rgba;
    this.currentColor = `rgba(${r}, ${g}, ${b}, ${a})`;
  }
}
