
import ColorPickerPopover from "@/components/common/ColorPickerPopover.vue";
import { ColorPickerColorModel } from "@/types/localtypes/colorPicker";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "EssentialsIcons",
  components: { ColorPickerPopover },
})
export default class EssentialsIcons extends Vue {
  iconsMap = [
    "icon-home",
    "icon-builds",
    "icon-artifacts",
    "icon-admin",
    "icon-search",
    "icon-plus-sign",
    "icon-trash",
    "icon-gz",
    "icon-tar",
    "icon-pin",
    "icon-unpin",
    "icon-revoke-api",
    "icon-unexpire",
    "icon-expire",
    "icon-cocoapods",
    "icon-go",
    "icon-trashcan-restore",
    "icon-trashcan",
    "icon-css",
    "icon-deb",
    "icon-docker",
    "icon-groovy",
    "icon-html",
    "icon-java",
    "icon-json",
    "icon-nuget",
    "icon-pdf",
    "icon-txt",
    "icon-xml",
    "icon-zip",
    "icon-artifacts-new",
    "icon-rpm",
    "icon-ruby-gems",
    "icon-scala",
    "icon-general",
    "icon-archive",
    "icon-c-1",
    "icon-c",
    "icon-c-2",
    "icon-cached-repo",
    "icon-distribution-repo",
    "icon-copy",
    "icon-group",
    "icon-go-to-build",
    "icon-folder",
    "icon-arrow",
    "icon-delete-content",
    "icon-delete-versions",
    "icon-local-repo",
    "icon-move",
    "icon-simple-browser",
    "icon-re-index",
    "icon-virtual-repo",
    "icon-v",
    "icon-unview",
    "icon-show-in-tree",
    "icon-show-in-ci-server",
    "icon-show-build-json",
    "icon-search-specific",
    "icon-menu-search",
    "icon-run",
    "icon-zap",
    "icon-refresh",
    "icon-view",
    "icon-archive-2",
    "icon-delete-recursive",
    "icon-gradle",
    "icon-filter",
    "icon-set-me-up",
    "icon-notif-success",
    "icon-notif-error",
    "icon-copy-to-clipboard",
    "icon-arrow2",
    "icon-artifactory-edit",
    "icon-jar",
    "icon-vagrant",
    "icon-yum",
    "icon-sbt",
    "icon-blackduck",
    "icon-pypi",
    "icon-git-lfs",
    "icon-remote-repo",
    "icon-deploy",
    "icon-datagrid-v",
    "icon-more",
    "icon-opkg",
    "icon-import",
    "icon-unlock",
    "icon-locked-unlock",
    "icon-lock",
    "icon-p2",
    "icon-npm",
    "icon-notif-icon",
    "icon-notif-warning",
    "icon-generic",
    "icon-export",
    "icon-duplicate",
    "icon-drag",
    "icon-new",
    "icon-ivy",
    "icon-info",
    "icon-clear",
    "icon-pro",
    "icon-upload-small",
    "icon-upload",
    "icon-download",
    "icon-vcs",
    "icon-pom",
    "icon-unwatch",
    "icon-watch",
    "icon-megaphone",
    "icon-megaphone-on",
    "icon-docker-id",
    "icon-docker-tags",
    "icon-docker-command",
    "icon-docker-created",
    "icon-docker-size",
    "icon-builds-new",
    "icon-home-new",
    "icon-docker-digest",
    "icon-admin-new",
    "icon-bintray",
    "icon-xray-alert",
    "icon-xray",
    "icon-xray-scanning",
    "icon-xray-impact-analysis",
    "icon-menu-arrow",
    "icon-logout",
    "icon-angle-double-up",
    "icon-angle-double-down",
  ];

  currentColor = "#696969";
  onColorChanged(color: ColorPickerColorModel) {
    const { r, g, b, a } = color.rgba;
    this.currentColor = `rgba(${r}, ${g}, ${b}, ${a})`;
  }
}
