
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import vSelect from "vue-select";
import { JFAlert } from "jfrog-ui-vue-essentials";
import { PaymentType } from "@jfrog-ba/myjfrog-common";
import { AsyncComponentProperties } from "@/types/asyncComponent";
import { PageTitleProps } from "@/types/layout/pageTitle";
import BackButton from "@/components/common/BackButton.vue";
import DynamicImage from "@/components/common/DynamicImage.vue";
import AsyncComponent from "@/components/common/AsyncComponent.vue";
import PulseSpinner from "@/components/spinners/PulseSpinner.vue";
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import BarChart from "@/components/charts/BarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import EssentialsIcons from "@/components/views/sandbox/EssentialsIcons.vue";
import MyJFrogIcons from "@/components/views/sandbox/MyJFrogIcons.vue";
import ArtifactoryIcons from "@/components/views/sandbox/ArtifactoryIcons.vue";
import PricingSlider from "@/components/pricingCalculator/PricingSlider.vue";
import ModalPricingCalculator from "@/components/pricingCalculator/modals/ModalPricingCalculator.vue";
import PageView from "@/components/layout/PageView.vue";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageSection from "@/components/layout/PageSection.vue";

@Component({
  name: "SandboxComponent",
  components: {
    JFAlert,
    BackButton,
    DynamicImage,
    AsyncComponent,
    PulseSpinner,
    Tabs,
    Tab,
    BarChart,
    LineChart,
    EssentialsIcons,
    MyJFrogIcons,
    ArtifactoryIcons,
    PageTitle,
    PageView,
    PageSection,
    PricingSlider,
  },
})
export default class SandboxComponent extends Vue {
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  @Prop() private test!: string;

  pageTitleProps: PageTitleProps = {
    title: "Sandbox Page",
    // backButtonConfig: { show: true, text: "text back" },
  };

  data1 = "dataVar";
  alertIsVisible = false;
  cbIsVisible = false;
  checkbox1: any = "false";
  tabsLoading = false;
  initialActiveTabId = "tab0";
  currentActiveTab = "tab0";

  hasError: boolean = false;
  errorMessage: string = "";
  dynamicImageName: string = "";
  animationInterval!: number;
  animationInterval2!: number;
  asyncComponentConfig: AsyncComponentProperties = {
    load: this.loadAsyncAlert,
    component: JFAlert,
    spinnerComponent: PulseSpinner,
    spinnerConfig: { size: "xxs", color: "#43a047", nbBalls: 5 },
    spinnerFlex: false,
    staticData: { type: "success" },
    autoHeight: true,
  };

  barChartData: {} | null = null;
  lineChartData: {} | null = null;

  @Watch("servers")
  onServersChange(value: any, oldValue: any) {}

  async mounted() {
    this.$jfModal.closeAll();
    setTimeout(() => {
      this.dynamicImageName = "jfrog.png";
    }, 300);

    this.animationInterval = setInterval(() => {
      this.$jfUtil.animateCss("#_notifBtn", "rubberBand");
    }, 2000);
    this.animationInterval2 = setInterval(() => {
      this.$jfUtil.animateCss("#_loaderBtn", "jello");
    }, 3000);
    this.fillBarData();
    this.fillLineData();
  }

  created() {
    setTimeout(() => {
      this.appendOnTheFly();
    }, 1000);
    setTimeout(() => {
      this.appendSelectOnTheFly();
    }, 2000);
  }

  appendOnTheFly() {
    var ComponentClass = Vue.extend(JFAlert);
    var instance = new ComponentClass({
      propsData: { content: "Append on the fly !", type: "success" },
    });
    // instance.$slots.default = ["Click me!"];
    instance.$mount(); // pass nothing7

    (this.$refs.appendToMe as HTMLDivElement).appendChild(instance.$el);
  }
  appendSelectOnTheFly() {
    // @ts-ignore
    var ComponentClass = Vue.extend(vSelect);
    var instance = new ComponentClass({
      propsData: { clearable: true, options: ["A", "B", "C"] },
    });
    instance.$on("input", (val: string) => {});
    instance.$mount();
    (this.$refs.appendToMe as HTMLDivElement).appendChild(instance.$el);
  }

  async loadAsyncAlert() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ content: "This is a JFAlert loaded from an async component!" });
      }, 3000);
    });
  }

  toggleLoader() {
    this.showAppLoadingMask();
    setTimeout(() => {
      this.hideAppLoadingMask();
    }, 1500);
  }

  addNotif() {
    this.$jfNotification.success({
      text: `New notification`,
      duration: -1,
    });
  }

  showConfirm(action: string) {
    this.$jfModal.confirm({
      title: "Are u sure ?",
      body: "My Body ",
      buttonsText: { confirm: "OK", cancel: "Close it" },
      clickShouldClose: false,
      size: "md",
      danger: true,
      onConfirm: checkboxChecked => {
        if (!checkboxChecked) {
          this.$jfNotification.error({ duration: 500, text: "Need to check the checkbox !" });
        }
        return checkboxChecked;
      },
      checkbox: {
        label: "My CBox",
        changeHandler: (checked: boolean) => {},
      },
    });
  }

  onTabShow(id: string, title: string) {
    this.$jfNotification.info({ text: `Showing tab : ${id} with title ${title}`, duration: 500 });
    this.currentActiveTab = id;
    // this.fillBarData();
  }

  toogleJFAlert() {
    this.alertIsVisible = !this.alertIsVisible;
  }

  toogleJFCheckBox() {
    this.cbIsVisible = !this.cbIsVisible;
  }

  fillBarData(): void {
    this.barChartData = {
      labels: ["Label " + this.getRandomInt(), "Label " + this.getRandomInt(), "Label " + this.getRandomInt()],
      datasets: [
        {
          label: "Data One",
          backgroundColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          borderWidth: 2,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
        {
          label: "Data Two",
          backgroundColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          borderWidth: 2,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
        {
          label: "Data Three",
          backgroundColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          borderWidth: 2,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
      ],
    };
  }
  fillLineData(): void {
    this.lineChartData = {
      labels: [
        "L " + this.getRandomInt(),
        "L " + this.getRandomInt(),
        "L " + this.getRandomInt(),
        "L " + this.getRandomInt(),
      ],
      datasets: [
        {
          label: "Data 1",
          // backgrousndColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          // borderWidth: 2,
          fill: false,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
        {
          label: "Data 2",
          // backgrsoundColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          // borderWidth: 2,
          fill: false,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
        {
          label: "Data 3",
          // backgroundColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          // borderWidth: 2,
          fill: false,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
        {
          label: "Data 4",
          lineTension: 0,
          backgroundColor: this.getRandomRGBA(),
          borderColor: this.getRandomHexColor(),
          // borderWidth: 2,
          fill: true,
          data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        },
      ],
    };
  }
  getRandomInt(): number {
    return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
  }
  getRandomHexColor(): string {
    return "#000000".replace(/0/g, function() {
      return (~~(Math.random() * 16)).toString(16);
    });
  }
  getRandomRGBA() {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + "," + r().toFixed(1) + ")";
  }

  displayModalPricingCalculator() {
    this.$jfModal.showCustom(
      ModalPricingCalculator,
      "md+-full-height",
      {
        planType: "pro",
        onlyPro: false,
        onApprove: (estimatedPaymentType: PaymentType) =>
          this.$jfNotification.success({ text: `subscribe to paymentType ${estimatedPaymentType}` }),
      },
      {
        forceDisplayTitle: true,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: true,
      },
    );
  }

  destroyed() {
    clearInterval(this.animationInterval);
    clearInterval(this.animationInterval2);
  }
}
