
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import PricingTable from "@/components/landingPages/quickPurchase/pricingTables/PricingTable.vue";
@Component({
  name: "SandboxComponent3",
  components: { PricingTable },
})
export default class SandboxComponent3 extends Vue {
  @Prop({ default: "" })
  private prop1!: string;
  data1 = "value1";
  get computedProp(): string {
    return [this.data1, this.prop1].join("-");
  }
}
