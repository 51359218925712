
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomModalHeader from "@/components/common/CustomModalHeader.vue";
import PricingSlider from "@/components/pricingCalculator/PricingSlider.vue";
import { PricingSliderProps } from "@/types/pricingSlider";
import { SubscriptionTypes, PaymentType, PricesTiersResponse, TiersDetails } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "ModalPricingCalculator",
  components: { CustomModalHeader, PricingSlider },
})
export default class ModalPricingCalculator extends Vue {
  @Prop() private tiersPrices!: PricesTiersResponse["ratePlanChargeMap"];
  @Prop({ default: "MONTHLY" })
  private initialPaymentType!: PaymentType;
  @Prop({ default: false })
  private onlyPro!: boolean;
  @Prop() private subscriptionTypes!: SubscriptionTypes[];
  @Prop() private onApprove!: (estimatedPaymentType: PaymentType) => void;

  proXStorageStartValue: number = 125;
  proXDTStartValue: number = 200;
  storageValue: number = this.proXStorageStartValue;
  dataTransferValue: number = this.proXDTStartValue;

  get currentStorageValue() {
    return this.storageValue;
  }
  get currentDTValue() {
    return this.dataTransferValue;
  }
  get isMobile() {
    return this.$mq === "mobile";
  }
  get sliderWidth() {
    const modalBodyWidth = window.innerWidth >= 800 ? 800 : window.innerWidth;
    const modalBodyPadding = this.isMobile ? 15 : 34;
    const columnPadding = 15;
    return modalBodyWidth - modalBodyPadding * 2 - columnPadding * 2;
  }

  get storageSliderProps(): PricingSliderProps {
    return {
      sliderWidth: this.sliderWidth,
      initialValue: this.storageValue,
      dataType: "storage",
      cloudProvider: "aws_and_gcp",
      includePro: this.initialPaymentType === "MONTHLY",
      includeProX: true,
      onlyPro: this.onlyPro,
    };
  }

  get dataTransferSliderProps(): PricingSliderProps {
    return {
      sliderWidth: this.sliderWidth,
      initialValue: this.dataTransferValue,
      dataType: "data_transfer",
      cloudProvider: "aws_and_gcp",
      includePro: this.initialPaymentType === "MONTHLY",
      includeProX: true,
      onlyPro: this.onlyPro,
    };
  }

  get estimatedPlan(): PaymentType {
    if (this.onlyPro) {
      return "MONTHLY";
    }
    return this.currentStorageValue >= this.proXStorageStartValue && this.currentDTValue >= this.proXDTStartValue
      ? "MONTHLY_PROX"
      : "MONTHLY";
  }

  get monthlyRateplanId() {
    const subscriptionType = this.subscriptionTypes.find(type => type.paymentType === "MONTHLY");
    if (subscriptionType) {
      return subscriptionType.ratePlanId;
    }
    return null;
  }
  get monthlyProXRateplanId() {
    const subscriptionType = this.subscriptionTypes.find(type => type.paymentType === "MONTHLY_PROX");
    if (subscriptionType) {
      return subscriptionType.ratePlanId;
    }
    return null;
  }

  get estimatedRatePlanId() {
    switch (this.estimatedPlan) {
      case "MONTHLY":
        return this.monthlyRateplanId;
      case "MONTHLY_PROX":
        return this.monthlyProXRateplanId;
    }
    this.$jfNotification.error({ text: this.$jfMessages.subscriptions_buy_error_calculator_error });
    return null;
  }

  get estimatedPlanIsProX() {
    return this.estimatedPlan === "MONTHLY_PROX";
  }

  get estimatedPlanName() {
    return this.estimatedPlanIsProX ? "Cloud Pro X" : "Cloud Pro";
  }
  get estimatedButtonPlanName() {
    return this.estimatedPlanIsProX ? "Pro X" : "Pro";
  }

  onStorageChange(value: number) {
    this.storageValue = value;
  }
  onDataTransferChange(value: number) {
    this.dataTransferValue = value;
  }

  onSubscribe() {
    this.onApprove && this.onApprove(this.estimatedPlan);
    this.dismiss();
  }

  get currentTiers() {
    return this.tiersPrices[this.estimatedRatePlanId as string];
  }
  get currentDTTier(): TiersDetails {
    const tier = this.currentTiers.dataTransfer.find(
      (tiersDetails: TiersDetails) =>
        this.currentDTValue >= parseFloat(tiersDetails.start) && this.currentDTValue <= parseFloat(tiersDetails.end),
    ) as TiersDetails;
    return tier;
  }

  get currentStorageTier(): TiersDetails {
    const tier = this.currentTiers.storage.find(
      (tiersDetails: TiersDetails) =>
        this.currentStorageValue >= parseFloat(tiersDetails.start) &&
        this.currentStorageValue <= parseFloat(tiersDetails.end),
    ) as TiersDetails;
    return tier;
  }

  get currentDTUsagePrice() {
    return parseInt(this.currentDTTier.price);
  }
  get currentStorageUsagePrice() {
    return parseInt(this.currentStorageTier.price);
  }

  get baseAmount(): number {
    const dtBase = parseInt(this.currentDTTier.basePrice);
    const storageBase = parseInt(this.currentStorageTier.basePrice);
    return dtBase + storageBase;
  }

  get usageAmount(): number {
    return this.currentDTUsagePrice + this.currentStorageUsagePrice - this.baseAmount;
  }
  get totalAmount(): number {
    return this.baseAmount + this.usageAmount;
  }

  get currentStoragePricePerGiga() {
    return (this.currentStorageUsagePrice / this.currentStorageValue).toFixed(2);
  }
  get currentDTPricePerGiga() {
    return (this.currentDTUsagePrice / this.currentDTValue).toFixed(2);
  }

  mounted() {
    this.$jfModal.childComponentIsSet();
  }

  dismiss() {
    this.$jfModal.dismiss();
  }
}
