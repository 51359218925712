
import { Component, Prop, Vue } from "vue-property-decorator";
import BackButton from "@/components/common/BackButton.vue";

@Component({
  name: "CustomModalHeader",
  components: {
    BackButton,
  },
})
export default class CustomModalHeader extends Vue {
  @Prop({ default: undefined })
  private onBack!: () => any;
  @Prop({ default: "" })
  private headerTitle!: string;

  dismiss() {
    this.$jfModal.dismiss();
  }
}
