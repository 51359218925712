
import SandboxComponent from "@/components/views/sandbox/SandboxComponent.vue";
import SandboxComponent3 from "@/components/views/sandbox/SandboxComponent3.vue";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  components: {
    SandboxComponent3,
    SandboxComponent,
  },
})
export default class Sandbox extends Vue {
  @Action("clearMenuConfiguration", { namespace: "application" })
  clearMenuConfiguration!: () => void;
  @Action("clearViewContext", { namespace: "application" })
  clearViewContext!: () => void;
  mounted() {
    this.clearMenuConfiguration();
    this.clearViewContext();
  }
}
